import {useFetch} from '../../hooks/useFetch'
import {NavLink} from 'react-router-dom'
import { Fragment, useState } from "react"

export default function Search(){
    
    const [searchurl, setSearchurl] = useState('')
    const { data} = useFetch(`booklist/?search=${searchurl}`)
    
    
    
    const searchDef = (e) => {
        setSearchurl(e.target.value)
        console.log(data.length==0)
    }

    return (
        <> 
            <div id="header-wrap" className='show my-3'>
                <div className="row text-center">
                    <div className="action-menu">
                        <div className="search-bar mx-auto my-5">
                            <a href="#" className="search-button search-toggle" data-selector="#header-wrap">
                                <i className="icon icon-search"></i>
                            </a>
                            <form role="search" method="get" className="search-box">
                                <input className="search-field text search-input" placeholder="Search"type="search"  onChange={(e) => searchDef(e)} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <section id="related-products" className="bookshelf pb-5 mb-5">
                <div className="container">
                    {data && data.length == 0 && (
                        <div>
                            No Result
                        </div>
                    )}
                    <div className="row">
                        <div className="inner-content">
                            <div className="product-list" data-aos="fade-up">
                                <div className="grid product-grid">

                                    {data && data.map((relate, index) => (
                                        <Fragment key={index}>
                                            
                                        {relate.id != data.id &&  <div className="product-item" key={index}>
                                                {relate.id != data.id && <><figure className="product-style">
                                                    <img src={relate.photo} alt="Books" className="product-item" />
                                                </figure>
                                                <figcaption>
                                                    <h3><NavLink to={`/product/${relate.id}`}>{relate.title}</NavLink></h3>
                                                    {/* <span>{category && category.title}</span> */}
                                                </figcaption> </>}
                                            </div>}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}