import logo from '../assets/images/main-logo.png'
import {NavLink} from 'react-router-dom'

export default function () {
    return (
        <>
            <footer id="footer">
                <div className="container">
                    <div className="row">

                        <div className="col-md-4">

                            <div className="footer-item">
                                <div className="company-brand">
                                    <img src={logo} alt="logo" className="footer-logo" />
                                    <p>Set goals, push limits. Your potential is limitless. Keep going :)</p>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-2">

                            <div className="footer-menu">
                                <h5>About Us</h5>
                                <ul className="menu-list">
                                    <li className="menu-item">
                                        <NavLink to={'article/'}>Articles </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to='aboutus/'>About Us</NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink href="#">Donate</NavLink>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="col-md-2">

                            <div className="footer-menu">
                                <h5>Discover</h5>
                                <ul className="menu-list">
                                    <li className="menu-item">
                                        <NavLink  to='/'>Home</NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink  to='featured/'>Books</NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink  to='search/'>Advanced Search</NavLink>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        {/* <div className="col-md-2">

                            <div className="footer-menu">
                                <h5>My account</h5>
                                <ul className="menu-list">
                                    <li className="menu-item">
                                        <a href="#">Sign In</a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="#">Create Account</a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="#">Forgat Password</a>
                                    </li>
                                </ul>
                            </div>

                        </div> */}
                        <div className="col-md-2">

                            <div className="footer-menu">
                                <h5>Help</h5>
                                <ul className="menu-list">
                                    <li className="menu-item">
                                        <NavLink to='contact/'>Report a problem</NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to='contact/'>Suggesting edits</NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to='contact/'>Contact us</NavLink>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>

                </div>
            </footer>

            <div id="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <div className="copyright">
                                <div className="row">

                                    <div className="col-md-6">
                                        <p>© 2024 All rights reserved.</p>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="social-links align-right">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/nasirahmad.ahmady.127"><i className="fa-brands fa-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://github.com/nasir-ahmad3"><i className="fa-brands fa-github"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.linkedin.com/in/nasir-ahmad-ahmady-a63009295/"><i className="fa-brands fa-linkedin"></i></a>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}