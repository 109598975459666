import {useFetch} from '../../hooks/useFetch'
import {NavLink, useLocation} from 'react-router-dom'

export default function AboutUS(){
    const {data} = useFetch('aboutus')


    return(
        <>
            	{data && 
                    <>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="page-title">ABOUT US</h1>
                                    <div className="breadcrumbs">
                                        <span className="item"><NavLink to='/'>Home /</NavLink></span>
                                        <span className="item">About us</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="padding-medium">
                        <div className="container">
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="post-content">
                                        {data.decsription}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </>
            }
                
        </>
    )
}