import {NavLink} from 'react-router-dom'
import {useFetch} from '../../hooks/useFetch'

export default function Contact(){
    const {data} = useFetch('aboutus/')

    return (
        <>
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="page-title">Contact us</h1>
                            <div class="breadcrumbs">
                                <span class="item"><NavLink to='/'>Home /</NavLink></span>
                                <span class="item">Contact us</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="contact-information padding-medium mt-3">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-md-6 mb-3">

                            <h2>Get in Touch</h2>

                            <div class="contact-detail d-flex flex-wrap mt-4">
                                <div class="detail mr-6 mb-4">
                                    <p>Feel free to reach out to us with any questions, suggestions, or inquiries you may have. We're here to help and eager to assist you in any way we can.</p>
                                    <ul class="list-unstyled list-icon">
                                        <li><i class="icon icon-phone"></i>{data && data.number}</li>
                                        <li><i class="icon icon-envelope-o"></i>
                                        <a href={`mailto:${data && data.email}`}>{data && data.email}</a></li>
                                    </ul>
                                </div>
                                <div class="detail mb-4">
                                    <h3>Social Links</h3>
                                    <div class="social-links flex-container">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/nasirahmad.ahmady.127"><i className="fa-brands fa-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://github.com/nasir-ahmad3"><i className="fa-brands fa-github"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.linkedin.com/in/nasir-ahmad-ahmady-a63009295/"><i className="fa-brands fa-linkedin"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-6">

                            <div class="contact-information">
                                <h2>Send A Message</h2>
                                <form name="contactform" action="contact.php" method="post"
                                    class="form-group contact-form mt-4">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="text" minlength="2" name="name" placeholder="Name" class="form-control"
                                                required />
                                        </div>
                                        <div class="col-md-6">
                                            <input type="email" name="email" placeholder="E-mail" class="form-control" required / >
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            
                                            <textarea class="form-control" name="message" placeholder="Message"
                                            style={{height: '150px'}} required></textarea>
                                            
                                        </div>
                                        <div class="col-md-12">
                                            <button type="submit" name="submit" class="btn btn-primary btn-rounded w-100">Submit</button>
                                        </div>
                                    </div>
                                </form>

                            </div>

                        </div>

                    </div>
                </div>
	</section>
        </>
    )
}