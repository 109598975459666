import {useFetch} from '../../hooks/useFetch'
import {NavLink, useLocation} from 'react-router-dom'

export default function ArticleDetail(){

    const location = useLocation();
    const pathname = location.pathname;
    const pathParts = pathname.split('/');
    const articleNumber = pathParts[pathParts.length - 1];

    const {data} = useFetch(`article/${articleNumber}/`)
    return(
        <>
            	{data && 
                    <>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="page-title">{data.title}</h1>
                                    <div className="breadcrumbs">
                                        <span className="item"><NavLink to='/'>Home /</NavLink></span>
                                        <span className="item">Article Detail</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="padding-medium">
                        <div className="container">
                            <div className="row">

                                <div className="col-md-12">

                                    <div className="post-thumbnail mb-5">
                                        <img src={data.image} alt="post thumbnail" className="img-fluid" />
                                    </div>

                                    <div className="post-content">
                                        {data.decsription}

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </>
            }
                
        </>
    )
}