import { NavLink } from 'react-router-dom'
import {useFetch} from '../../hooks/useFetch'
import { useState } from 'react'


export default function  Feature(){
    const [urlnum, setUrlNum] = useState(1)
    const {data} = useFetch(`featured/?page=${urlnum}`)
    const nextPage = () => {
        setUrlNum( prevValue =>  prevValue+1)
    }
    const prevPage = () => {
        setUrlNum( prevValue =>  prevValue-1)
    }

    return (
        <>
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="colored">
                                <h1 className="page-title">Feature</h1>
                                <div className="breadcum-items">
                                    <span className="item"><NavLink to={'/'}>Home /</NavLink></span>
                                    <span className="item colored">Feature</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="padding-medium">
                <div className="container">
                    <div className="row">
                        {data && data.results.map((book, index) => (
                                <div className="col-md-3" key={index}>
                                    <div className="product-item">
                                        <figure className="product-style">
                                            <img src={book.photo} alt="Books" className="product-item" />
                                        </figure>
                                        <figcaption>
                                            <h3><NavLink to={`/product/${book.id}`}>{book.title}</NavLink></h3>
                                        </figcaption>
                                    </div>
                                </div>
                            ))
                        }                        
                    </div>

                    { data && <div className="row">

                        <nav aria-label="Page navigation" className="mt-5">
                        <ul className="pagination justify-content-center">
                            <li className="page-item">
                            { data.previous &&< a className="page-link" aria-label="Previous" onClick={() => prevPage()}>
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            }
                            </li>
                            <li className="page-item active" aria-current="page"><a className="page-link" href="#">{urlnum}</a></li>
                            <li className="page-item">
                            {
                                data.next &&< a className="page-link" aria-label="Next" onClick={() => nextPage()}>
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            }
                            </li>
                        </ul>
                        </nav>
                    </div>}

                </div>
            </section>
        </>
    )
}