import { Fragment } from "react"
import { useFetch } from "../hooks/useFetch"
import { NavLink } from 'react-router-dom'

export default function Featured(){
    const {data} = useFetch('featuredlist/') 
    const { data : category } = useFetch(`category/${data?.category}`)

    return(
        

        <section id="related-products" className="bookshelf pb-5 mb-5">
            <div className="container">
                <div className="section-header align-center">
                    <div className="title">
                        <span>Related Products</span>
                    </div>
                    <h2 className="section-title">You may also like</h2>
                </div>
            </div>
            
            <div className="container">
                <div className="row">
                    <div className="inner-content">
                        <div className="product-list" data-aos="fade-up">
                            <div className="grid product-grid">

                                {data && data.map((relate, index) => (
                                    <Fragment key={index}>
                                        
                                    {relate.id != data.id &&  <div className="product-item" key={index}>
                                            {relate.id != data.id && <><figure className="product-style">
                                                <img src={relate.photo} alt="Books" className="product-item" />
                                            </figure>
                                            <figcaption>
                                                <h3><NavLink to={`/product/${relate.id}`}>{relate.title}</NavLink></h3>
                                                <span>{category && category.title}</span>
                                            </figcaption> </>}
                                        </div>}
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">

                        <div className="btn-wrap align-right">
                            <NavLink to={'/featured/'} className="btn-accent-arrow">View all products <i className="icon icon-ns-arrow-right"></i></NavLink>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}