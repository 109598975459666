
import { NavLink } from 'react-router-dom'
import {useFetch} from '../hooks/useFetch'

export default function BookOfTheDay () {
    const {data} = useFetch('bookfftheday')
    const {data: book} = useFetch(`book/${data && data[0] ? data[0].book : ''}`)


    return (
            <section id="best-selling" className="leaf-pattern-overlay">
            <div className="corner-pattern-overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-md-8">

                            <div className="row">

                                <div className="col-md-6">
                                    <figure className="products-thumb">
                                        <img src="images/single-image.jpg" alt="book" className="single-image" />
                                    </figure>
                                </div>

                                <div className="col-md-6">
                                    <div className="product-entry">
                                        <h2 className="section-title divider">Book Of The Day</h2>

                                        {book && <div className="products-content">
                                            {/* <div className="author-name">By Timbur Hood</div> */}
                                            <h3 className="item-title">{book.title}</h3>
                                            <p>{book.about.length > 250 ?  `${book.about.substring(0,250)}.....` : book.about}</p>
                                            <div className="btn-wrap">
                                                <NavLink to={`product/${book.id}`} className="btn-accent-arrow">
                                                    Read More 
                                                    <i className="icon icon-ns-arrow-right"></i>
                                                </NavLink>
                                            </div>
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}