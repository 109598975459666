import './Nav.css'
import logo from '../assets/images/main-logo.png'
import React, { useEffect, useState } from 'react';
import $ from 'jquery'; 
import { NavLink, useLocation} from 'react-router-dom';

export default function Nav(){
    const [active, setActive] = useState(false)
    useEffect(() => {
        // Initialize tab functionality
        const tabs = $('[data-tab-target]');
        const tabContents = $('[data-tab-content]');
      
        tabs.on('click', function () {
          const target = $($(this).data('tab-target'));
          tabContents.removeClass('active');
          tabs.removeClass('active');
          $(this).addClass('active');
          target.addClass('active');
        });
      
        // Initialize hamburger menu
        const hamburger = $('#hamburger');
        const navMenu = $('.menu-list');
      
        hamburger.on('click', function () {
          hamburger.toggleClass('active');
          navMenu.toggleClass('responsive');
        });
      
        // Initialize scroll navigation
        function initScrollNav() {
          const scroll = $(window).scrollTop();
          const header = $('#header');
      
          if (scroll >= 200) {
            header.addClass('fixed-top');
          } else {
            header.removeClass('fixed-top');
          }
        }
      
        $(window).on('scroll', initScrollNav);
        initScrollNav();
    }, []);

    const location = useLocation()
    const [activeClass, setActiveClass] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          // Condition to toggle active class based on page size
          if (window.innerWidth < 768) {
            setActiveClass(true);
          } else {
            setActiveClass(false);
          }
        };
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Call handleResize initially to set the initial state
        handleResize();
    
        // Cleanup function to remove event listener
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    return(
        <div id="header-wrap">
                    <div className="top-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="social-links">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/nasirahmad.ahmady.127"><i className="fa-brands fa-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://github.com/nasir-ahmad3"><i className="fa-brands fa-github"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.linkedin.com/in/nasir-ahmad-ahmady-a63009295/"><i className="fa-brands fa-linkedin"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="right-element">
                                        <a target='_blank' href="https://nasir3.pythonanywhere.com" className="user-account for-buy">
                                            <span>More About Me</span>
                                        </a>
                                        <div className="action-menu">

                                            <div className="search-bar">
                                                <NavLink to='/search/' className="search-button search-toggle" data-selector="#header-wrap">
                                                    <i className="icon icon-search"></i>
                                                </NavLink>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <header id="header">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-md-2">
                                    <div className="main-logo">
                                        <NavLink to="/"><img src={logo} alt="logo"/></NavLink>
                                    </div>

                                </div>

                                <div className="col-md-10">
                                    <nav id="navbar">
                                        <div class={`main-menu stellarnav ${active ? 'active' : ''} ${activeClass ? 'mobile' : '' }`}>
                                            <a onClick={ () => setActive(prev => prev ? false : true)} class="menu-toggle full">
                                                <span class="bars">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </span>
                                                Menu
                                            </a>
                                            <ul className="menu-list">
                                                <li className={location.pathname == '/' ? "menu-item active" : "menu-item"}><NavLink to="/">Home</NavLink></li>
                                                <li className="menu-item"><NavLink to='/featured/' className="nav-link">Featured</NavLink></li>
                                                <li className="menu-item"><NavLink to='/article/' className="nav-link">Articles</NavLink></li>
                                                <li className="menu-item"><NavLink to='/aboutus/' className="nav-link">About</NavLink></li>
                                                <li className="menu-item"><NavLink to='/contact/' className="nav-link">Contact</NavLink></li>
                                            </ul>

                                            <div className="hamburger" id="hamburger">
                                                <span className="bar"></span>
                                                <span className="bar"></span>
                                                <span className="bar"></span>
                                            </div>

                                        </div>
                                    </nav>

                                </div>

                            </div>
                        </div>
                    </header>

                </div>
                
    )
}