import './App.css';
import Home from './pages/Home/Home';
import Nav from './componentes/Nav';
import Product from './pages/Prodouct/Product';
import { BrowserRouter, Routes, Route } from "react-router-dom";


import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Footer from './componentes/Footer';
import Featured from './pages/Feature/Feature.js';
import ArticleList from './pages/ArticleList/ArticleList.js';
import ArticleDetail from './pages/ArticleDetail/ArticleDetail.js'
import AboutUS from './pages/AboutUs/AboutUS.js'
import Contact from './pages/Contact/Contact.js';
import Search from './pages/Search/Search.js';


function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  
  return (
    <>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/"  element={<Home />}/>
          <Route path="/product/:id" element={<Product />}/>
          <Route path='/featured/' element={<Featured />} />
          <Route path='/article/' element={<ArticleList />} />
          <Route path='/article/:id' element={<ArticleDetail />}/>
          <Route path='/aboutus/' element={<AboutUS />}/>
          <Route path='/contact/' element={<Contact />} />
          <Route path='/search/' element={<Search />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App; 
