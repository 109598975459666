import {useFetch} from '../hooks/useFetch'

export default function QuoteOfTheDay(){
    const {data} = useFetch('quoteoftheday/')

    return (
        <>
            {data &&<section id="quotation" className="align-center pb-5 mb-5">
                <div className="inner-content">
                    <h2 className="section-title divider">Quote of the day</h2>
                    <blockquote data-aos="fade-up" className="aos-init aos-animate">
                        <q>“{data.description}”</q>
                        <div className="author-name">{data.author}</div>
                    </blockquote>
                </div>
            </section>}
        </>
    )
}