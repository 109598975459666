import {useFetch} from '../hooks/useFetch'
import {NavLink} from 'react-router-dom'

export default function ArticleList(){
    const {data} = useFetch('articlelist/')
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    return(
        <> 
            {data && <section id="latest-blog" className="py-5 my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <div className="section-header align-center">
                                <div className="title">
                                    <span>Read our articles</span>
                                </div>
                                <h2 className="section-title">Latest Articles</h2>
                            </div>

                            <div className="row">

                                {data.map((article, index) => (
                                    <div className="col-md-4" key={index}>
                                        <article className="column" data-aos="fade-up">

                                            <figure>
                                                <NavLink to={`article/${article.id}`} className="image-hvr-effect">
                                                    <img src={article.image} alt="post" className="post-image"/>
                                                </NavLink>
                                            </figure>

                                            <div className="post-item">
                                                <div className="meta-date">{formatDate(article.publish)}</div>
                                                <h3><NavLink to={`article/${article.id}`}>{article.title}</NavLink></h3>

                                                <div className="links-element">
                                                    <div className="categories">{article.category}</div>
                                                    <div className="social-links">
                                                        <ul>
                                                            <li>
                                                                <a href={`https://twitter.com/intent/tweet?url=[URL]&text=[${window.location.href}]`} target="_blank" rel="noopener noreferrer"><i className="icon icon-twitter"></i></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </article>
                                    </div>                                
                                ))}
                            </div>

                            <div className="row">

                                <div className="btn-wrap align-center">
                                    <NavLink to="article" className="btn btn-outline-accent btn-accent-arrow" tabindex="0">
                                        Read All Articles
                                        <i className="icon icon-ns-arrow-right"></i>
                                    </NavLink>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>}
        </>
    )
}