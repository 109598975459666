import './Header.css'
import React, { useEffect } from 'react';
import $ from 'jquery';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useFetch } from '../hooks/useFetch';
import {NavLink} from 'react-router-dom'


export default function Home() {
  useEffect(() => {
    // Close search menu when clicking off the container
    const handleOutsideClick = (e) => {
      if (!$(e.target).closest('.search-toggle, #header-wrap').length) {
        $('.search-toggle').removeClass('active');
        $('#header-wrap').removeClass('show');
      }
    };

    $(document).on('click touchstart', handleOutsideClick);

    return () => {
      $(document).off('click touchstart', handleOutsideClick);
    };
  }, []);

  const {data} = useFetch('booklist/')


  return (
    <>
      <section id="billboard">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Slider 
                autoplay={false}  
                autoplaySpeed={4000}
                fade={true}
                className="main-slider"
              >

              {data && data.map((book, index) => (
                <div className="slider-item" key={index}>
                  <div className="banner-content">
                    <h2 className="banner-title">{book.title}</h2>
                    <p>{book.about.length > 250 ?  `${book.about.substring(0,250)}.....` : book.about}</p>
                    <div className="btn-wrap">
                      <NavLink to={`/product/${book.id}/`} className="btn btn-outline-accent btn-accent-arrow">Read More<i
                          className="icon icon-ns-arrow-right"></i></NavLink>
                    </div>
                  </div>
                  <img src={book.photo} alt="banner" className="banner-image" />
                </div>
              ))}            

              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section id="client-holder" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="inner-content">
              <div className="logo-wrap">
                <div className="grid">
                  <img src="images/client-image1.png" alt="client" />
                  <img src="images/client-image2.png" alt="client" />
                  <img src="images/client-image3.png" alt="client" />
                  <img src="images/client-image4.png" alt="client" />
                  <img src="images/client-image5.png" alt="client" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
