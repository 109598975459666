import {useFetch} from '../../hooks/useFetch'
import {NavLink} from 'react-router-dom'
import { useState } from 'react'

export default function ArticleList(){
    const [urlnum, setUrlNum] = useState(1)
    const {data} = useFetch(`allarticlelist/?page=${urlnum}`)
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };
    const nextPage = () => {
        setUrlNum( prevValue =>  prevValue+1)
    }
    const prevPage = () => {
        setUrlNum( prevValue =>  prevValue-1)
    }

    return(
        <> 
            <section class="hero-section jarallax">

                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="page-title">Our Blog</h1>
                            <div class="breadcrumbs">
                                <span class="item"><NavLink to={'/'}>Home /</NavLink></span>
                                <span class="item">Our Blog</span>
                            </div>
                        </div>
                    </div>
                </div>

                </section>
            {data && <section id="latest-blog" className="py-5 my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">

                                {data.results.map((article, index) => (
                                    <div className="col-md-4" key={index}>
                                        <article className="column" data-aos="fade-up">

                                            <figure>
                                                <NavLink to={`${article.id}`} className="image-hvr-effect">
                                                    <img src={article.image} alt="post" className="post-image"/>
                                                </NavLink>
                                            </figure>

                                            <div className="post-item">
                                                <div className="meta-date">{formatDate(article.publish)}</div>
                                                <h3><NavLink to={`${article.id}`}>{article.title}</NavLink></h3>

                                                <div className="links-element">
                                                    <div className="categories">{article.category}</div>
                                                    <div className="social-links">
                                                        <ul>
                                                            <li>
                                                                <a href={`https://twitter.com/intent/tweet?url=[URL]&text=[${window.location.href}]`} target="_blank" rel="noopener noreferrer"><i className="icon icon-twitter"></i></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </article>
                                    </div>                                
                                ))}
                            </div>

                            

                            { data && <div className="row">

                                <nav aria-label="Page navigation" className="mt-5">
                                <ul className="pagination justify-content-center">
                                    <li className="page-item">
                                    { data.previous &&< a className="page-link" aria-label="Previous" onClick={() => prevPage()}>
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    }
                                    </li>
                                    <li className="page-item active" aria-current="page"><a className="page-link" href="#">{urlnum}</a></li>
                                    <li className="page-item">
                                    {
                                        data.next &&< a className="page-link" aria-label="Next" onClick={() => nextPage()}>
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    }
                                    </li>
                                </ul>
                                </nav>
                            </div>}
                        </div>
                    </div>
                </div>
            </section>}
        </>
    )
}