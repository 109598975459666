import { NavLink, useParams } from 'react-router-dom'
import {useFetch} from '../../hooks/useFetch'
import { useState, Fragment } from 'react'
export default function Product(){

    const [myCategory, setMyCategory] = useState(null)

    const {id} = useParams()
    const {data, err} = useFetch(`book/${id}`)
    const [activeTab, setActiveTab] = useState('nav-home')
    const { data : category } = useFetch(`category/${data?.category}`)
    const {data: related} = useFetch(`booklist/${data?.category}`)
    const {data : download_book} = useFetch(`book/${id}`)

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handel_download = () => {

  }
  const downloadFile = async (fileId) => {
    try {
        const downloadUrl = `http://localhost:8000/download/${fileId}/`;
        // const downloadOptions = {
        //     headers: {
        //         'Authorization': 'Token 0dc19eab8765757454d8310452d0d338b634ad47',
        //     },
        // };
        const response = await fetch(downloadUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${data.title} - digitals-downloads.com.pdf`);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error('Error downloading file:', error);
    }
}

    return ( 
        <>
            {data && <section className="bg-sand padding-medium">
                <div className="container">
                    <div className="row">

                        <div className="col-md-5">
                            <a href="#" className="product-image"><img src={data.photo}/></a>
                        </div>

                        <div className="col-md-7 pl-5">
                            <div className="product-detail">
                                <span>{category && category.title}</span>
                                <h1>{data.title}</h1>                               
                                <p>
                                    {data.about}    
                                </p>
                                <div className="d-flex gap-3">
                                    <button type="submit" name="add-to-cart" value="27545" className="button m-0" onClick={() => downloadFile(id)} >Download</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>}

            <section className="product-tabs mt-5">
                <div className="container">
                    <div className="row">
                        <div className="tabs-listing">
                            <nav>
                                <div className="nav nav-tabs d-flex justify-content-center" id="nav-tab" role="tablist">
                                    <button
                                        className={`nav-link text-uppercase px-5 py-3 ${activeTab === 'nav-home' ? 'active' : ''}`}
                                        id="nav-home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-home"
                                        aria-selected={activeTab === 'nav-home'}
                                        onClick={() => handleTabClick('nav-home')}
                                    >
                                        Description
                                    </button>
                                    <button
                                        className={`nav-link text-uppercase px-5 py-3 ${activeTab === 'nav-information' ? 'active' : ''}`}
                                        id="nav-information-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-information"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-information"
                                        aria-selected={activeTab === 'nav-information'}
                                        onClick={() => handleTabClick('nav-information')}
                                    >
                                        Additional information
                                    </button>
                                </div>
                            </nav>
                            <div className="tab-content py-5" id="nav-tabContent">
                                <div className={`tab-pane fade ${activeTab === 'nav-home' ? 'active show' : ''}`} id="nav-home" role="tabpanel"
                                    aria-labelledby="nav-home-tab">
                                    <h5>Product Description</h5>
                                    {data && data.description}
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'nav-information' ? 'active show' : ''}`} id="nav-information" role="tabpanel"
                                    aria-labelledby="nav-information-tab">
                                    <h5>It is Comfortable and Best</h5>
                                    <p>{data && data.additional_information}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="related-products" className="bookshelf pb-5 mb-5">
                <div className="container">
                    <div className="section-header align-center">
                        <div className="title">
                            <span>Related Products</span>
                        </div>
                        <h2 className="section-title">You may also like</h2>
                    </div>
                </div>
                
                <div className="container">
                    <div className="row">
                        <div className="inner-content">
                            <div className="product-list" data-aos="fade-up">
                                <div className="grid product-grid">

                                    {related && related.map((relate, index) => (
                                        <Fragment key={index}>
                                        {relate.id != data.id &&  <div className="product-item" key={index}>
                                            {relate.id != data.id && <><figure className="product-style">
                                                <img src={relate.photo} alt="Books" className="product-item" />
                                            </figure>
                                            <figcaption>
                                                <h3><NavLink to={`/product/${relate.id}`}>{relate.title}</NavLink></h3>
                                                <span>{category && category.title}</span>
                                            </figcaption> </>}
                                        </div>}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}