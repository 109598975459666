import './Home.css'
import '../../assets/css/normalize.css'
import '../../assets/icomoon/icomoon.css'
import '../../assets/css/vendor.css'
import '../../assets/style.css'

import Header from '../../componentes/Header'
import Featured from '../../componentes/Featured'
import BookOfTheDay from '../../componentes/BookOfTheDay'
import QuoteOfTheDay from '../../componentes/QuoteOfTheDay'
import Subscribe from '../../componentes/Subscribe'
import ArticleList from '../../componentes/ArticleList'



export default function Home (){

    return (
        <>
            <Header />
            <Featured />
            <BookOfTheDay />
            <QuoteOfTheDay />
            <Subscribe />
            <ArticleList />
        </>
    )
}